import React from 'react';
import { MehOutlined } from '@ant-design/icons';
import { Avatar, Popover } from 'antd';

const ChamberHeadCount = ({ count }) => {
  const manInfo = (
    <div className="man-info">
      <Avatar
        src="https://us.123rf.com/450wm/warrengoldswain/warrengoldswain1309/warrengoldswain130900153/22256397-portrait-of-real-man-face-looking-at-camera-on-blue-background.jpg"
        size={50}
      />
      <div className="info">
        <div className="name">Andrew H</div>
        <div className="sub">ID Status xxxx</div>
      </div>
      <div className="last-seen">
        <div className="title">Last Seen</div>
        <div className="number">10</div>
        <div className="minute">Min</div>
      </div>
    </div>
  );

  return (
    <div className="tooltip-content">
      {Array(count)
        .fill({})
        .map((_, index) => (
          <Popover content={manInfo} key={index}>
            <div className="man">
              <span className="id">L10{index}</span>
              <MehOutlined />
            </div>
          </Popover>
        ))}
    </div>
  );
};

export default ChamberHeadCount;
