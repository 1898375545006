import draw2d from 'draw2d';
import ReactDOM from 'react-dom';
import ChamberHeadCount from './ChamberHeadCount';

const { $ } = window;

const IconWithLabel = draw2d.shape.icon.Icon.extend({
  NAME: 'draw2d.helper.IconWithLabel',
  init: function (attr, setter, getter) {
    this._super($.extend({ width: 20, height: 20 }, attr), setter, getter);
    this.setRotationAngle(180);
    this.addCssClass('add-stroke-path');
    this.installEditPolicy(new draw2d.policy.figure.AntSelectionFeedbackPolicy());

    this.label = new draw2d.shape.basic.Label({ text: '0', color: '#00000000', fontColor: '#fff', bold: true });

    this.add(this.label, new draw2d.helper.ItemLabelLocator(this));
    this.label.installEditor(new draw2d.ui.LabelInplaceEditor());
  },

  getPersistentAttributes: function () {
    const memento = this._super();
    memento.labels = [];
    this.children.each(function (i, e) {
      const labelJSON = e.figure.getPersistentAttributes();
      labelJSON.locator = e.locator.NAME;
      memento.labels.push(labelJSON);
    });

    return memento;
  },

  setPersistentAttributes: function (memento) {
    this._super(memento);
    this.resetChildren();
    $.each(
      memento.labels,
      $.proxy((i, json) => {
        const figure = eval('new ' + json.type + '()');
        figure.attr(json);
        this.add(figure, eval('new ' + json.locator + '()'));
      }, this),
    );
  },
});

export const setHelperElements = () => {
  draw2d.helper = {
    FactoryIcon: draw2d.shape.icon.Icon.extend({
      NAME: 'draw2d.helper.FactoryIcon',
      init: function (attr, setter, getter) {
        this._super($.extend({ width: 70, selectable: false, draggable: false }, attr), setter, getter);
        this.setColor('#414141');
      },

      createSet: function () {
        return this.canvas.paper.path(
          'M203.333,104.983v82.451c0,2.761-2.239,5-5,5H5c-2.761,0-5-2.239-5-5v-58.34c0-1.896,1.072-3.628,2.768-4.474l48.333-24.111\n' +
            '\tc1.55-0.773,3.39-0.689,4.862,0.222c1.473,0.911,2.37,2.52,2.37,4.252v16.029l29.597-14.764V57.416c0-2.761,2.239-5,5-5h9.074\n' +
            '\tc2.761,0,5,2.239,5,5v63.428l33.021-16.472l2.044-88.589c0.063-2.716,2.282-4.885,4.999-4.885h21.797\n' +
            '\tc2.716,0,4.936,2.169,4.999,4.885l2.186,94.729l20.051-10.002c1.55-0.773,3.389-0.689,4.862,0.222S203.333,103.251,203.333,104.983z\n' +
            '\t M35.078,151.969H21.553v19.006h13.525V151.969z M71.703,151.969H58.178v19.006h13.525V151.969z M108.329,151.969H94.804v19.006\n' +
            '\th13.525V151.969z M144.955,151.969H131.43v19.006h13.525V151.969z',
        );
      },
    }),

    ItemLabelLocator: draw2d.layout.locator.Locator.extend({
      NAME: 'draw2d.helper.ItemLabelLocator',
      relocate: function (index, target) {
        const boundingBox = target.getParent().getBoundingBox();
        const targetBoundingBox = target.getBoundingBox();
        target.setPosition(boundingBox.w / 2 - targetBoundingBox.w / 2, -(targetBoundingBox.h - 2));
      },
    }),

    AirSensor: IconWithLabel.extend({
      NAME: 'draw2d.helper.AirSensor',
      init: function (attr, setter, getter) {
        this._super(attr, setter, getter);
        this.setColor('green');
      },

      createSet: function () {
        return this.canvas.paper.path(
          'M23.963,20.834L17.5,9.64c-0.825-1.429-2.175-1.429-3,0L8.037,20.834c-0.825,1.429-0.15,2.598,1.5,2.598h12.926C24.113,23.432,24.788,22.263,23.963,20.834z',
        );
      },
    }),

    Chamber: IconWithLabel.extend({
      NAME: 'draw2d.helper.Chamber',
      init: function (attr, setter, getter) {
        this.tooltip = null;
        this.tooltipTimer = -1;

        this._super(attr, setter, getter);
        this.setColor('white');
        this.setCssClass('chamber');

        this.on('dragstart', () => {
          this.hideTooltip(true);
        });
        this.on('mouseenter', () => {
          this.tooltipTimer = window.setTimeout(() => {
            this.tooltipTimer = -1;
            this.showTooltip();
          }, 50);
        });
        this.on('mouseleave', () => {
          this.hideTooltip();
        });
        this.on('move', () => {
          this.positionTooltip();
        });
      },

      createSet: function () {
        return this.canvas.paper.path(
          'm 290.21 438.89 v 152 c 0 53.74 43.27 97 97 97 h 55 c 53.74 0 97 -43.26 97 -97 v -152 h 0 z',
        );
      },

      hideTooltip: function (fast) {
        if (this.tooltipTimer !== -1) {
          window.clearTimeout(this.tooltipTimer);
          this.tooltipTimer = -1;
        } else if (this.tooltip !== null) {
          if (fast) {
            this.tooltip.remove();
          } else {
            this.tooltip.fadeOut(50, function () {
              $(this).remove();
            });
          }
          this.tooltip = null;
        }
      },

      showTooltip: function () {
        if (this.disableTooltip) {
          return false;
        }
        const ele =
          '<div class="cmb-tooltip ant-popover-inner" role="tooltip"><div class="man-list" id="tooltip-content"></div></div>';

        this.tooltip = $(ele).appendTo('body').hide().fadeIn(100);
        this.positionTooltip();

        const heads = 10;

        ReactDOM.render(<ChamberHeadCount count={heads} />, document.getElementById('tooltip-content'));
      },

      positionTooltip: function () {
        if (this.tooltip === null) {
          return;
        }

        const width = this.tooltip.outerWidth(true);
        const pos = this.canvas.fromCanvasToDocumentCoordinate(
          this.getAbsoluteX() + this.getWidth() / 2 - width / 2 + 8,
          this.getAbsoluteY() - 40,
        );

        // remove the scrolling part from the tooltip because the tooltip is placed
        // inside the scrolling container
        pos.x += this.canvas.getScrollLeft();
        pos.y += this.canvas.getScrollTop();

        this.tooltip.css({ top: pos.y, left: pos.x });
      },
    }),

    LevelEnd: draw2d.shape.basic.Polygon.extend({
      NAME: 'draw2d.helper.LevelEnd',
      init: function (attr) {
        this._super($.extend({ bgColor: '#c5e2ff', color: '#ffffff' }, attr));

        this.resetVertices();

        const box = this.getBoundingBox();
        box.setWidth(30);
        box.setHeight(50);

        this.addVertex(box.w - 10, 0);
        this.addVertex(box.w, 0);
        this.addVertex(10, box.h);
        this.addVertex(0, box.h);

        this.createPort('hybrid', new draw2d.layout.locator.CenterLocator(this));

        this.installEditPolicy(new draw2d.policy.figure.AntSelectionFeedbackPolicy());

        this.setPosition(box.getTopLeft());
      },
    }),

    EntranceIcon: draw2d.shape.basic.Polygon.extend({
      NAME: 'draw2d.helper.EntranceIcon',
      init: function (attr) {
        this._super($.extend({ bgColor: '#cdcdcd', draggable: false, selectable: false }, attr));

        this.resetVertices();

        const box = this.getBoundingBox();
        box.setWidth(30);
        box.setHeight(50);

        this.addVertex(0, 0);
        this.addVertex(box.w, 0);
        this.addVertex(box.w - 5, 15);
        this.addVertex(17, 15);
        this.addVertex(17, box.h);
        this.addVertex(13, box.h);
        this.addVertex(13, 15);
        this.addVertex(5, 15);

        this.installEditPolicy(new draw2d.policy.figure.AntSelectionFeedbackPolicy());

        this.setPosition(box.getTopLeft());
      },
    }),

    VBusSelectionFP: draw2d.policy.figure.BusSelectionFeedbackPolicy.extend({
      NAME: 'draw2d.helper.VBusSelectionFP',
      init: function (attr, setter, getter) {
        this._super(attr, setter, getter);
      },
      moved: function (canvas, figure) {
        if (figure.selectionHandles.isEmpty()) {
          return; // silently
        }

        const r2 = figure.selectionHandles.find((handle) => handle.type === 2);
        const r6 = figure.selectionHandles.find((handle) => handle.type === 6);
        const r4 = figure.selectionHandles.find((handle) => handle.type === 4);
        const r8 = figure.selectionHandles.find((handle) => handle.type === 8);

        const objWidth = figure.getWidth();
        r2.hide();
        r6.setDimension(objWidth, r6.getHeight());
        r4.hide();
        r8.hide();
        this._super(canvas, figure);
      },
    }),

    EntranceFigure: draw2d.shape.icon.Icon.extend({
      NAME: 'draw2d.helper.EntranceFigure',

      init: function (attr, setter, getter) {
        this._super($.extend({ width: 150, height: 50, cssClass: 'add-stroke-path' }, attr), setter, getter);
        this.setColor('#cdcdcd');
        this.installEditPolicy(new draw2d.policy.figure.AntSelectionFeedbackPolicy());
      },

      createSet: function () {
        return this.canvas.paper.path(
          'm98.445003,163.660308l53.250006,88.999995l177.500031,0l53.249987,-88.999995l-284.000025,0z',
        );
      },
    }),

    ChamberEntrance: draw2d.shape.node.Hub.extend({
      NAME: 'draw2d.helper.ChamberEntrance',
      init: function (attr, setter, getter) {
        this._super(attr, setter, getter);

        this.setConnectionDirStrategy(2);
        this.installEditPolicy(new draw2d.helper.VBusSelectionFP());
        // this.addPort(new draw2d.InputPort());

        // this.createPort('hybrid', new draw2d.layout.locator.DraggableLocator(this));
        this.add(new draw2d.helper.EntranceFigure(), new draw2d.layout.locator.TopLocator(this));
        this.setBackgroundColor('#cdcdcd');
        this.setStroke(0);
        this.installEditPolicy(new draw2d.policy.figure.HorizontalEditPolicy());
      },
    }),

    CopyInterceptorPolicy: draw2d.policy.canvas.PanningSelectionPolicy.extend({
      NAME: 'CopyInterceptorPolicy',

      init: function () {
        this._super();
        this.cloneOnDrag = false;
      },

      onMouseDown: function (canvas, x, y, shiftKey, ctrlKey) {
        this.cloneOnDrag = shiftKey;
        this._super(canvas, x, y, shiftKey, ctrlKey);
      },

      onMouseDrag: function (canvas, dx, dy, dx2, dy2, shiftKey, ctrlKey) {
        if (
          !(this.mouseDraggingElement instanceof draw2d.ResizeHandle) &&
          !(this.mouseDraggingElement instanceof draw2d.Port)
        ) {
          if (
            this.mouseDraggingElement !== null &&
            (this.cloneOnDrag === true || this.mouseDraggingElement.noShiftKeyToClone)
          ) {
            // get the current position of the selected shape
            var pos = this.mouseDraggingElement.getPosition();

            // cancel the current drag&drop operation
            this.mouseDraggingElement.onDragEnd(pos.x, pos.y, false, false);

            console.log('===', this.mouseDraggingElement);
            // clone the selection
            this.mouseDraggingElement = this.mouseDraggingElement.clone();
            // add the clone to the canvas and start dragging of the clone
            this.mouseDraggingElement.installEditPolicy(new draw2d.policy.figure.AntSelectionFeedbackPolicy());
            canvas.add(this.mouseDraggingElement, pos);

            // select the cloned shape
            this.select(canvas, this.mouseDraggingElement);

            // start dragging if the clone accept this operation
            this.mouseDraggingElement.onDragStart(pos.x, pos.y, false, false);
          }
        }

        this.cloneOnDrag = false;

        this._super(canvas, dx, dy, dx2, dy2, shiftKey, ctrlKey);
      },
    }),
  };
};
