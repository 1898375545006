import 'import-jquery';
import 'jquery-ui-bundle';
import 'jquery-ui-bundle/jquery-ui.css';
import jquery from 'jquery';
import draw2d from 'draw2d';

import 'antd/dist/antd.css';
import './App.css';
import Layout from './Components/Layout';

window.$ = window.jQuery = jquery;
window.draw2d = draw2d;

function App() {
  return (
    <div className="App">
      <Layout />
    </div>
  );
}

export default App;
