import React, { useEffect, useState } from 'react';
import { Drawer } from './drawer';
import { setHelperElements } from './helpers';
import axios from 'axios';

setHelperElements();

const drawer = Drawer.getInstance();

const Canvas = ({ editing }) => {
  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    if (editing) {
      setFirstLoad(false);
    }
  }, [editing]);

  useEffect(() => {
    // axios
    //   .get('http://localhost:4000/data')
    //   .then((data) => {
    //     if (data && data.data && data.data.data) {
    //       drawer.initDrawer('canvas', data.data.data);
    //     } else {
    //       drawer.initDrawer('canvas');
    //     }
    //   })
    //   .catch(() => {
    //     drawer.initDrawer('canvas');
    //   });

    try {
      const data = JSON.parse(localStorage.getItem('_minemap') || '[]');
      drawer.initDrawer('canvas', data);
    } catch (e) {
      console.error('Error read from local storage', e);
      drawer.initDrawer('canvas');
    }
  }, []);

  return <div id="canvas" className={firstLoad ? 'no-editing' : ''} />;
};

export default Canvas;
