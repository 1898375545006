import draw2d from 'draw2d';
import { jsonDocument } from './drawing-data';
import suncalc from 'suncalc';

const now = new Date();
const sun = suncalc.getTimes(now, 6.927079, 79.861244);
const isDay = sun.sunrise.getTime() < now.getTime() && sun.sunset.getTime() > now.getTime();
export class Drawer {
  static instance;
  canvas;

  static getInstance() {
    if (!Drawer.instance) {
      Drawer.instance = new Drawer();
    }
    return Drawer.instance;
  }

  initDrawer1 = (canvasId) => {
    this.canvas = new draw2d.Canvas(canvasId);
    this.canvas.installEditPolicy(new draw2d.helper.CopyInterceptorPolicy());
    // unmarshal the JSON document into the canvas
    // (load)
    const reader = new draw2d.io.json.Reader();
    reader.unmarshal(this.canvas, jsonDocument);
  };

  initDrawer = (canvasId, json) => {
    this.canvas = new draw2d.Canvas(canvasId);
    this.canvas.installEditPolicy(new draw2d.policy.canvas.ReadOnlySelectionPolicy());

    this.canvas.installEditPolicy(new draw2d.policy.canvas.SnapToGeometryEditPolicy());
    this.canvas.installEditPolicy(new draw2d.policy.canvas.SnapToInBetweenEditPolicy());
    this.canvas.installEditPolicy(new draw2d.policy.canvas.SnapToCenterEditPolicy());

    // 1. air
    // const environment = new draw2d.shape.basic.Rectangle({
    //   width: this.canvas.getWidth(),
    //   height: 120,
    //   bgColor: '#50a5f5',
    //   selectable: false,
    //   draggable: false,
    //   id: 'no-save-1',
    // });
    const environment = new draw2d.shape.basic.Image({
      path: isDay ? './assets/day.jpeg' : './assets/night.jpg',
      width: this.canvas.getWidth(),
      height: 120,
      id: 'no-save-1',
    });
    this.canvas.add(environment, 0, 0);

    // 2. factory
    const factoryFigure = new draw2d.helper.FactoryIcon({ id: 'no-save-2' });
    this.canvas.add(factoryFigure, this.canvas.getWidth() / 2 - 35, 69);

    // 3. toolbox
    const toolboxContainer = new draw2d.shape.basic.Rectangle({
      height: 70,
      width: 200,
      bgColor: '#a0cfff',
      selectable: false,
      draggable: false,
      id: 'no-save-3',
      visible: false,
    });
    toolboxContainer.onlyOnEditMode = true;
    this.canvas.add(toolboxContainer, this.canvas.getWidth() - 205, 45);

    const infoLabel = new draw2d.shape.note.PostIt({
      text: 'Drag from here',
      x: this.canvas.getWidth() - 200,
      y: 10,
      id: 'no-save-7',
      visible: false,
    });
    infoLabel.onlyOnEditMode = true;
    this.canvas.add(infoLabel);

    // 4. toolbox elements
    const draggableAirSensor = new draw2d.helper.AirSensor({ id: 'no-save-4', visible: false });
    draggableAirSensor.onlyOnEditMode = true;
    draggableAirSensor.noShiftKeyToClone = true;
    draggableAirSensor.children.get(0).figure.setVisible(false);
    this.canvas.add(draggableAirSensor, toolboxContainer.getX() + 10, toolboxContainer.getY() + 30);

    const draggableChamber = new draw2d.helper.Chamber({ id: 'no-save-5', visible: false });
    draggableChamber.onlyOnEditMode = true;
    draggableChamber.disableTooltip = true;
    draggableChamber.noShiftKeyToClone = true;
    draggableChamber.children.get(0).figure.setVisible(false);
    this.canvas.add(draggableChamber, toolboxContainer.getX() + 45, toolboxContainer.getY() + 30);

    const draggableLevelEnd = new draw2d.helper.LevelEnd({ id: 'no-save-6', visible: false });
    draggableLevelEnd.noShiftKeyToClone = true;
    draggableLevelEnd.onlyOnEditMode = true;
    this.canvas.add(draggableLevelEnd, toolboxContainer.getX() + 75, toolboxContainer.getY() + 10);

    const addEntranceButton = new draw2d.helper.EntranceIcon({ id: 'no-save-7', visible: false });
    addEntranceButton.onlyOnEditMode = true;
    addEntranceButton.on('click', () => {
      const entrance = new draw2d.helper.ChamberEntrance({ width: 10, height: 500 });
      this.canvas.add(entrance, 80, 170);
    });
    this.canvas.add(addEntranceButton, toolboxContainer.getX() + 115, toolboxContainer.getY() + 10);

    const addPathConnectionButton = new draw2d.shape.icon.Ethernet({
      width: 30,
      height: 30,
      id: 'no-save-8',
      visible: false,
      draggable: false,
      selectable: false,
    });
    addPathConnectionButton.onlyOnEditMode = true;
    addPathConnectionButton.on('click', () => {
      console.log('here');
      const router = new draw2d.layout.connection.VertexRouter();
      router.abortRoutingOnFirstVertexNode = false;
      const f1 = new draw2d.shape.node.Fulcrum();
      this.canvas.add(f1, 400, 200);
      const f2 = new draw2d.shape.node.Fulcrum();
      this.canvas.add(f2, 400, 300);

      const c = new draw2d.Connection({ router: router, color: 'gray' });
      c.setSource(f1.getHybridPort(0));
      c.setTarget(f2.getHybridPort(0));

      this.canvas.add(c);
    });
    this.canvas.add(addPathConnectionButton, toolboxContainer.getX() + 155, toolboxContainer.getY() + 20);

    // load data from backend
    if (json) {
      const reader = new draw2d.io.json.Reader();
      reader.unmarshal(this.canvas, json);
    }

    window.draw = this.canvas;
  };
}
