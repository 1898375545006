export const jsonDocument = [
  {
    type: 'draw2d.shape.basic.Rectangle',
    id: '91a8d07b-2ec4-78de-92c6-88243de902ee',
    x: 0,
    y: 0,
    width: 1000,
    height: 120,
    alpha: 1,
    selectable: false,
    draggable: false,
    angle: 0,
    userData: {},
    cssClass: 'draw2d_shape_basic_Rectangle',
    ports: [],
    bgColor: 'rgba(80,165,245,1)',
    color: 'rgba(27,27,27,1)',
    stroke: 1,
    radius: 0,
    dasharray: null,
  },
  {
    type: 'draw2d.helper.FactoryIcon',
    id: 'dd4846b3-b047-93a2-2994-ccbdd577008b',
    x: 465,
    y: 69,
    width: 70,
    height: 50,
    alpha: 1,
    selectable: false,
    draggable: false,
    angle: 0,
    userData: {},
    cssClass: 'draw2d_helper_FactoryIcon',
    ports: [],
    bgColor: 'rgba(0,0,0,0)',
    color: 'rgba(65,65,65,1)',
    stroke: 0,
    radius: 0,
    dasharray: null,
  },
  {
    type: 'draw2d.shape.basic.Rectangle',
    id: '081b419c-d2ef-a210-7709-822c00444209',
    x: 845,
    y: 45,
    width: 150,
    height: 70,
    alpha: 1,
    selectable: false,
    draggable: false,
    angle: 0,
    userData: {},
    cssClass: 'draw2d_shape_basic_Rectangle',
    ports: [],
    bgColor: 'rgba(160,207,255,1)',
    color: 'rgba(27,27,27,1)',
    stroke: 1,
    radius: 0,
    dasharray: null,
  },
  {
    type: 'draw2d.shape.note.PostIt',
    id: '15cc1313-2051-960e-4e53-f52d8a810cad',
    x: 800,
    y: 10,
    width: 142.625,
    height: 23,
    alpha: 1,
    selectable: false,
    draggable: false,
    angle: 0,
    userData: {},
    cssClass: 'draw2d_shape_note_PostIt',
    ports: [],
    bgColor: 'rgba(91,91,91,1)',
    color: 'rgba(255,255,255,1)',
    stroke: 1,
    radius: 5,
    dasharray: null,
    text: 'Drag from here with Shift Key',
    outlineStroke: 0,
    outlineColor: 'rgba(0,0,0,0)',
    fontSize: 14,
    fontColor: 'rgba(255,255,255,1)',
    fontFamily: null,
  },
  {
    type: 'draw2d.helper.AirSensor',
    id: 'c9db9cea-f1cf-5259-704b-e067eb65e7cb',
    x: 865,
    y: 75,
    width: 20,
    height: 20,
    alpha: 1,
    selectable: true,
    draggable: true,
    angle: 180,
    userData: {},
    cssClass: 'draw2d_helper_AirSensor',
    ports: [],
    bgColor: 'rgba(0,0,0,0)',
    color: 'rgba(0,128,0,1)',
    stroke: 0,
    radius: 0,
    dasharray: null,
  },
  {
    type: 'draw2d.helper.Chamber',
    id: '5977b99c-7c3f-a70b-a4b5-f29adc658900',
    x: 895,
    y: 75,
    width: 20,
    height: 20,
    alpha: 1,
    selectable: true,
    draggable: true,
    angle: 180,
    userData: {},
    cssClass: 'draw2d_helper_Chamber',
    ports: [],
    bgColor: 'rgba(0,0,0,0)',
    color: 'rgba(255,255,255,1)',
    stroke: 0,
    radius: 0,
    dasharray: null,
  },
  {
    type: 'draw2d.helper.LevelEnd',
    id: '9bb3f6a9-99a6-34d7-d421-a49919b30e7d',
    x: 880,
    y: 385,
    width: 30,
    height: 50,
    alpha: 1,
    selectable: true,
    draggable: true,
    angle: 0,
    userData: {},
    cssClass: 'draw2d_helper_LevelEnd',
    ports: [
      {
        type: 'draw2d.HybridPort',
        id: '139aa8d2-d638-898f-8f68-cec22c9696dc',
        width: 10,
        height: 10,
        alpha: 1,
        selectable: false,
        draggable: true,
        angle: 0,
        userData: {},
        cssClass: 'draw2d_HybridPort',
        bgColor: 'rgba(79,104,112,1)',
        color: 'rgba(27,27,27,1)',
        stroke: 1,
        dasharray: null,
        maxFanOut: 9007199254740991,
        name: 'hybrid0',
        semanticGroup: 'global',
        port: 'draw2d.HybridPort',
        locator: 'draw2d.layout.locator.CenterLocator',
        locatorAttr: {},
      },
    ],
    bgColor: 'rgba(0,163,246,1)',
    color: 'rgba(27,27,27,1)',
    stroke: 1,
    radius: 0,
    dasharray: null,
    vertices: [
      {
        x: 900,
        y: 385,
      },
      {
        x: 910,
        y: 385,
      },
      {
        x: 890,
        y: 435,
      },
      {
        x: 880,
        y: 435,
      },
    ],
  },
  {
    type: 'draw2d.helper.ChamberEntrance',
    id: '956f61b2-00c2-16f8-d002-fb5fdc0012f0',
    x: 368,
    y: 170,
    width: 10,
    height: 500,
    alpha: 1,
    selectable: true,
    draggable: true,
    angle: 0,
    userData: {},
    cssClass: 'draw2d_helper_ChamberEntrance',
    bgColor: 'rgba(205,205,205,1)',
    color: 'rgba(69,216,229,1)',
    stroke: 0,
    radius: 0,
    dasharray: null,
    dirStrategy: 2,
  },
  {
    type: 'draw2d.helper.ChamberEntrance',
    id: 'b9db4bb2-19a9-5fa1-51c2-9e28619313f4',
    x: 696,
    y: 170,
    width: 10,
    height: 500,
    alpha: 1,
    selectable: true,
    draggable: true,
    angle: 0,
    userData: {},
    cssClass: 'draw2d_helper_ChamberEntrance',
    bgColor: 'rgba(205,205,205,1)',
    color: 'rgba(69,216,229,1)',
    stroke: 0,
    radius: 0,
    dasharray: null,
    dirStrategy: 2,
  },
  {
    type: 'draw2d.helper.LevelEnd',
    id: '557da464-f16e-1b22-1aeb-fbfc39552c92',
    x: 874,
    y: 524,
    width: 30,
    height: 50,
    alpha: 1,
    selectable: true,
    draggable: true,
    angle: 0,
    userData: {},
    cssClass: 'draw2d_helper_LevelEnd',
    ports: [
      {
        type: 'draw2d.HybridPort',
        id: '1f378e5d-d99d-a843-6a41-8bda3359ebf7',
        width: 10,
        height: 10,
        alpha: 1,
        selectable: false,
        draggable: true,
        angle: 0,
        userData: {},
        cssClass: 'draw2d_HybridPort',
        bgColor: 'rgba(79,104,112,1)',
        color: 'rgba(27,27,27,1)',
        stroke: 1,
        dasharray: null,
        maxFanOut: 9007199254740991,
        name: 'hybrid0',
        semanticGroup: 'global',
        port: 'draw2d.HybridPort',
        locator: 'draw2d.layout.locator.CenterLocator',
        locatorAttr: {},
      },
    ],
    bgColor: 'rgba(0,163,246,1)',
    color: 'rgba(27,27,27,1)',
    stroke: 1,
    radius: 0,
    dasharray: null,
    vertices: [
      {
        x: 894,
        y: 524,
      },
      {
        x: 904,
        y: 524,
      },
      {
        x: 884,
        y: 574,
      },
      {
        x: 874,
        y: 574,
      },
    ],
  },
  {
    type: 'draw2d.helper.LevelEnd',
    id: '7e49d726-8d03-7892-e6f0-fc01d24b9266',
    x: 499,
    y: 501,
    width: 30,
    height: 50,
    alpha: 1,
    selectable: true,
    draggable: true,
    angle: 0,
    userData: {},
    cssClass: 'draw2d_helper_LevelEnd',
    ports: [
      {
        type: 'draw2d.HybridPort',
        id: '74b92fa1-bdb8-e261-1634-9c20263cbc69',
        width: 10,
        height: 10,
        alpha: 1,
        selectable: false,
        draggable: true,
        angle: 0,
        userData: {},
        cssClass: 'draw2d_HybridPort',
        bgColor: 'rgba(79,104,112,1)',
        color: 'rgba(27,27,27,1)',
        stroke: 1,
        dasharray: null,
        maxFanOut: 9007199254740991,
        name: 'hybrid0',
        semanticGroup: 'global',
        port: 'draw2d.HybridPort',
        locator: 'draw2d.layout.locator.CenterLocator',
        locatorAttr: {},
      },
    ],
    bgColor: 'rgba(0,163,246,1)',
    color: 'rgba(27,27,27,1)',
    stroke: 1,
    radius: 0,
    dasharray: null,
    vertices: [
      {
        x: 519,
        y: 501,
      },
      {
        x: 529,
        y: 501,
      },
      {
        x: 509,
        y: 551,
      },
      {
        x: 499,
        y: 551,
      },
    ],
  },
  {
    type: 'draw2d.helper.LevelEnd',
    id: 'bcfef359-d274-5fc8-b7a2-f28d476cf7fc',
    x: 166,
    y: 265,
    width: 30,
    height: 50,
    alpha: 1,
    selectable: true,
    draggable: true,
    angle: 0,
    userData: {},
    cssClass: 'draw2d_helper_LevelEnd',
    ports: [
      {
        type: 'draw2d.HybridPort',
        id: 'a4914cce-371e-0caa-35a4-84a51417d30c',
        width: 10,
        height: 10,
        alpha: 1,
        selectable: false,
        draggable: true,
        angle: 0,
        userData: {},
        cssClass: 'draw2d_HybridPort',
        bgColor: 'rgba(79,104,112,1)',
        color: 'rgba(27,27,27,1)',
        stroke: 1,
        dasharray: null,
        maxFanOut: 9007199254740991,
        name: 'hybrid0',
        semanticGroup: 'global',
        port: 'draw2d.HybridPort',
        locator: 'draw2d.layout.locator.CenterLocator',
        locatorAttr: {},
      },
    ],
    bgColor: 'rgba(0,163,246,1)',
    color: 'rgba(27,27,27,1)',
    stroke: 1,
    radius: 0,
    dasharray: null,
    vertices: [
      {
        x: 186,
        y: 265,
      },
      {
        x: 196,
        y: 265,
      },
      {
        x: 176,
        y: 315,
      },
      {
        x: 166,
        y: 315,
      },
    ],
  },
  {
    type: 'draw2d.helper.LevelEnd',
    id: '43b91710-d162-5235-ed26-2ae01644819c',
    x: 878,
    y: 299,
    width: 30,
    height: 50,
    alpha: 1,
    selectable: true,
    draggable: true,
    angle: 0,
    userData: {},
    cssClass: 'draw2d_helper_LevelEnd',
    ports: [
      {
        type: 'draw2d.HybridPort',
        id: 'a5300e47-f7f8-b583-a34b-bbd4a14dcd81',
        width: 10,
        height: 10,
        alpha: 1,
        selectable: false,
        draggable: true,
        angle: 0,
        userData: {},
        cssClass: 'draw2d_HybridPort',
        bgColor: 'rgba(79,104,112,1)',
        color: 'rgba(27,27,27,1)',
        stroke: 1,
        dasharray: null,
        maxFanOut: 9007199254740991,
        name: 'hybrid0',
        semanticGroup: 'global',
        port: 'draw2d.HybridPort',
        locator: 'draw2d.layout.locator.CenterLocator',
        locatorAttr: {},
      },
    ],
    bgColor: 'rgba(0,163,246,1)',
    color: 'rgba(27,27,27,1)',
    stroke: 1,
    radius: 0,
    dasharray: null,
    vertices: [
      {
        x: 898,
        y: 299,
      },
      {
        x: 908,
        y: 299,
      },
      {
        x: 888,
        y: 349,
      },
      {
        x: 878,
        y: 349,
      },
    ],
  },
  {
    type: 'draw2d.helper.AirSensor',
    id: '90a87b78-e4c5-f81b-70f7-3ead7109be8d',
    x: 858,
    y: 292,
    width: 20,
    height: 20,
    alpha: 1,
    selectable: true,
    draggable: true,
    angle: 180,
    userData: {},
    cssClass: 'draw2d_helper_AirSensor',
    ports: [],
    bgColor: 'rgba(0,0,0,0)',
    color: 'rgba(0,128,0,1)',
    stroke: 0,
    radius: 0,
    dasharray: null,
  },
  {
    type: 'draw2d.helper.AirSensor',
    id: 'f67dc4a2-6066-7351-5542-e41bde80c5a6',
    x: 764,
    y: 300,
    width: 20,
    height: 20,
    alpha: 1,
    selectable: true,
    draggable: true,
    angle: 180,
    userData: {},
    cssClass: 'draw2d_helper_AirSensor',
    ports: [],
    bgColor: 'rgba(0,0,0,0)',
    color: 'rgba(0,128,0,1)',
    stroke: 0,
    radius: 0,
    dasharray: null,
  },
  {
    type: 'draw2d.helper.AirSensor',
    id: '3e20ac86-8e0e-e803-95d8-cc4f885dd167',
    x: 782,
    y: 379,
    width: 20,
    height: 20,
    alpha: 1,
    selectable: true,
    draggable: true,
    angle: 180,
    userData: {},
    cssClass: 'draw2d_helper_AirSensor',
    ports: [],
    bgColor: 'rgba(0,0,0,0)',
    color: 'rgba(0,128,0,1)',
    stroke: 0,
    radius: 0,
    dasharray: null,
  },
  {
    type: 'draw2d.helper.AirSensor',
    id: '0f8b3a8a-72bd-3903-2ebe-376e3ba35c4c',
    x: 478,
    y: 498,
    width: 20,
    height: 20,
    alpha: 1,
    selectable: true,
    draggable: true,
    angle: 180,
    userData: {},
    cssClass: 'draw2d_helper_AirSensor',
    ports: [],
    bgColor: 'rgba(0,0,0,0)',
    color: 'rgba(0,128,0,1)',
    stroke: 0,
    radius: 0,
    dasharray: null,
  },
  {
    type: 'draw2d.helper.Chamber',
    id: '28d6937e-366f-a14d-79f6-8ec4c95295ba',
    x: 315,
    y: 257,
    width: 20,
    height: 20,
    alpha: 1,
    selectable: true,
    draggable: true,
    angle: 180,
    userData: {},
    cssClass: 'draw2d_helper_Chamber',
    ports: [],
    bgColor: 'rgba(0,0,0,0)',
    color: 'rgba(255,255,255,1)',
    stroke: 0,
    radius: 0,
    dasharray: null,
  },
  {
    type: 'draw2d.Connection',
    id: '9040555f-9df0-b44f-f4d7-20a1b35739dc',
    alpha: 1,
    selectable: true,
    draggable: true,
    angle: 0,
    userData: {},
    cssClass: 'draw2d_Connection',
    stroke: 2,
    color: 'rgba(18,156,228,1)',
    outlineStroke: 0,
    outlineColor: 'rgba(0,0,0,0)',
    policy: 'draw2d.policy.line.LineSelectionFeedbackPolicy',
    vertex: [
      {
        x: 181,
        y: 290,
      },
      {
        x: 368,
        y: 290,
      },
    ],
    router: 'draw2d.layout.connection.DirectRouter',
    radius: 3,
    source: {
      node: 'bcfef359-d274-5fc8-b7a2-f28d476cf7fc',
      port: 'hybrid0',
    },
    target: {
      node: '956f61b2-00c2-16f8-d002-fb5fdc0012f0',
      port: 'hybrid0',
    },
  },
  {
    type: 'draw2d.Connection',
    id: 'f0ad32b9-30c4-03f0-fed4-0704f9713400',
    alpha: 1,
    selectable: true,
    draggable: true,
    angle: 0,
    userData: {},
    cssClass: 'draw2d_Connection',
    stroke: 2,
    color: 'rgba(18,156,228,1)',
    outlineStroke: 0,
    outlineColor: 'rgba(0,0,0,0)',
    policy: 'draw2d.policy.line.LineSelectionFeedbackPolicy',
    vertex: [
      {
        x: 514,
        y: 526,
      },
      {
        x: 378,
        y: 526,
      },
    ],
    router: 'draw2d.layout.connection.DirectRouter',
    radius: 3,
    source: {
      node: '7e49d726-8d03-7892-e6f0-fc01d24b9266',
      port: 'hybrid0',
    },
    target: {
      node: '956f61b2-00c2-16f8-d002-fb5fdc0012f0',
      port: 'hybrid0',
    },
  },
  {
    type: 'draw2d.Connection',
    id: 'fe4ac89e-fe19-dea0-f9b5-ca3806923a96',
    alpha: 1,
    selectable: true,
    draggable: true,
    angle: 0,
    userData: {},
    cssClass: 'draw2d_Connection',
    stroke: 2,
    color: 'rgba(18,156,228,1)',
    outlineStroke: 0,
    outlineColor: 'rgba(0,0,0,0)',
    policy: 'draw2d.policy.line.LineSelectionFeedbackPolicy',
    vertex: [
      {
        x: 895,
        y: 410,
      },
      {
        x: 706,
        y: 410,
      },
    ],
    router: 'draw2d.layout.connection.DirectRouter',
    radius: 3,
    source: {
      node: '9bb3f6a9-99a6-34d7-d421-a49919b30e7d',
      port: 'hybrid0',
    },
    target: {
      node: 'b9db4bb2-19a9-5fa1-51c2-9e28619313f4',
      port: 'hybrid0',
    },
  },
  {
    type: 'draw2d.Connection',
    id: '4468f3cb-c5f2-fa7f-fbc2-ea992de1ecab',
    alpha: 1,
    selectable: true,
    draggable: true,
    angle: 0,
    userData: {},
    cssClass: 'draw2d_Connection',
    stroke: 2,
    color: 'rgba(18,156,228,1)',
    outlineStroke: 0,
    outlineColor: 'rgba(0,0,0,0)',
    policy: 'draw2d.policy.line.LineSelectionFeedbackPolicy',
    vertex: [
      {
        x: 893,
        y: 324,
      },
      {
        x: 706,
        y: 324,
      },
    ],
    router: 'draw2d.layout.connection.DirectRouter',
    radius: 3,
    source: {
      node: '43b91710-d162-5235-ed26-2ae01644819c',
      port: 'hybrid0',
    },
    target: {
      node: 'b9db4bb2-19a9-5fa1-51c2-9e28619313f4',
      port: 'hybrid0',
    },
  },
  {
    type: 'draw2d.Connection',
    id: '2630b0d1-3fd5-3255-07bb-75e517c8194e',
    alpha: 1,
    selectable: true,
    draggable: true,
    angle: 0,
    userData: {},
    cssClass: 'draw2d_Connection',
    stroke: 2,
    color: 'rgba(18,156,228,1)',
    outlineStroke: 0,
    outlineColor: 'rgba(0,0,0,0)',
    policy: 'draw2d.policy.line.LineSelectionFeedbackPolicy',
    vertex: [
      {
        x: 889,
        y: 549,
      },
      {
        x: 706,
        y: 549,
      },
    ],
    router: 'draw2d.layout.connection.DirectRouter',
    radius: 3,
    source: {
      node: '557da464-f16e-1b22-1aeb-fbfc39552c92',
      port: 'hybrid0',
    },
    target: {
      node: 'b9db4bb2-19a9-5fa1-51c2-9e28619313f4',
      port: 'hybrid0',
    },
  },
];
export const jsonDocument1 = [
  {
    type: 'draw2d.shape.node.HorizontalBus',
    id: '8f389cf7-1088-7343-2fa3-e6ca0338f45d',
    x: 100,
    y: 60,
    width: 600,
    height: 20,
    alpha: 1,
    selectable: true,
    draggable: true,
    angle: 0,
    userData: {},
    cssClass: 'draw2d_shape_node_HorizontalBus',
    bgColor: 'rgba(41,170,119,1)',
    color: 'rgba(69,216,229,1)',
    stroke: 1,
    radius: 0,
    dasharray: null,
    dirStrategy: 1,
  },
  {
    type: 'draw2d.shape.node.VerticalBus',
    id: 'a394aeb4-4074-647f-ebe5-b4d6e475fdc0',
    x: 400,
    y: 160,
    width: 22,
    height: 200,
    alpha: 1,
    selectable: true,
    draggable: true,
    angle: 0,
    userData: {},
    cssClass: 'draw2d_shape_node_VerticalBus',
    ports: [
      {
        type: 'draw2d.HybridPort',
        id: '78d0c5ce-5e80-c648-b0a0-4f59dd857563',
        width: 10,
        height: 10,
        alpha: 1,
        selectable: false,
        draggable: true,
        angle: 0,
        userData: {},
        cssClass: 'draw2d_HybridPort',
        bgColor: 'rgba(79,104,112,1)',
        color: 'rgba(27,27,27,1)',
        stroke: 1,
        dasharray: null,
        maxFanOut: 9007199254740991,
        name: 'hybrid0',
        semanticGroup: 'global',
        port: 'draw2d.HybridPort',
        locator: 'draw2d.layout.locator.CenterLocator',
        locatorAttr: {},
      },
    ],
    bgColor: 'rgba(41,170,119,1)',
    color: 'rgba(69,216,229,1)',
    stroke: 1,
    radius: 0,
    dasharray: null,
    dirStrategy: 2,
    label: 'vertical bus',
  },
  {
    type: 'draw2d.shape.node.Fulcrum',
    id: '89e72eb7-95e1-6067-8e80-acdddc55ebd0',
    x: 300,
    y: 360,
    width: 40,
    height: 40,
    alpha: 1,
    selectable: true,
    draggable: true,
    angle: 0,
    userData: {},
    cssClass: 'draw2d_shape_node_Fulcrum',
    ports: [
      {
        type: 'draw2d.HybridPort',
        id: '107d7c54-aa61-ca5e-c98d-84a5afdf4ecd',
        width: 10,
        height: 10,
        alpha: 1,
        selectable: false,
        draggable: true,
        angle: 0,
        userData: {},
        cssClass: 'draw2d_HybridPort',
        bgColor: 'rgba(79,104,112,1)',
        color: 'rgba(27,27,27,1)',
        stroke: 1,
        dasharray: null,
        maxFanOut: 9007199254740991,
        name: 'hybrid0',
        semanticGroup: 'global',
        port: 'draw2d.HybridPort',
        locator: 'draw2d.layout.locator.CenterLocator',
        locatorAttr: {},
      },
    ],
    bgColor: 'rgba(0,0,0,0)',
    color: 'rgba(0,0,0,0)',
    stroke: 0,
    radius: 10,
    dasharray: null,
    dirStrategy: 0,
  },
  {
    type: 'draw2d.shape.node.Start',
    id: 'd43f9145-5128-7651-2927-678654650ce2',
    x: 50,
    y: 250,
    width: 50,
    height: 50,
    alpha: 1,
    selectable: true,
    draggable: true,
    angle: 0,
    userData: {},
    cssClass: 'draw2d_shape_node_Start',
    ports: [
      {
        type: 'draw2d.OutputPort',
        id: '54c7e1ae-7e88-dd1a-b7ce-8c800eb5c548',
        width: 10,
        height: 10,
        alpha: 1,
        selectable: false,
        draggable: true,
        angle: 0,
        userData: {},
        cssClass: 'draw2d_OutputPort',
        bgColor: 'rgba(79,104,112,1)',
        color: 'rgba(27,27,27,1)',
        stroke: 1,
        dasharray: null,
        maxFanOut: 9007199254740991,
        name: 'output0',
        semanticGroup: 'global',
        port: 'draw2d.OutputPort',
        locator: 'draw2d.layout.locator.OutputPortLocator',
        locatorAttr: {},
      },
    ],
    bgColor: 'rgba(77,144,254,1)',
    color: 'rgba(69,130,229,1)',
    stroke: 1,
    radius: 0,
    dasharray: null,
  },
  {
    type: 'draw2d.shape.node.End',
    id: '97173656-c1df-0d42-0495-81f7a49f1904',
    x: 630,
    y: 250,
    width: 50,
    height: 50,
    alpha: 1,
    selectable: true,
    draggable: true,
    angle: 0,
    userData: {},
    cssClass: 'draw2d_shape_node_End',
    ports: [
      {
        type: 'draw2d.InputPort',
        id: '6a10fe31-c205-f5ce-6a58-83d618b08cea',
        width: 10,
        height: 10,
        alpha: 1,
        selectable: false,
        draggable: true,
        angle: 0,
        userData: {},
        cssClass: 'draw2d_InputPort',
        bgColor: 'rgba(79,104,112,1)',
        color: 'rgba(27,27,27,1)',
        stroke: 1,
        dasharray: null,
        maxFanOut: 9007199254740991,
        name: 'input0',
        semanticGroup: 'global',
        port: 'draw2d.InputPort',
        locator: 'draw2d.layout.locator.InputPortLocator',
        locatorAttr: {},
      },
    ],
    bgColor: 'rgba(77,144,254,1)',
    color: 'rgba(69,130,229,1)',
    stroke: 1,
    radius: 0,
    dasharray: null,
  },
];
