import React, { useState } from 'react';
import axios from 'axios';
import { Button, Col, Row, Switch } from 'antd';
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  FullscreenOutlined,
  RedoOutlined,
  UndoOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';
import { Drawer } from './Draw/drawer';
import draw2d from 'draw2d';

const Navbar = ({ editing, setEditing }) => {
  const zoom = (out) => {
    const { canvas } = Drawer.getInstance();
    canvas.setZoom(canvas.getZoom() * (out ? 1.3 : 0.7), true);
  };
  const zoomReset = () => {
    const { canvas } = Drawer.getInstance();
    canvas.setZoom(1.0, true);
  };

  const undo = () => {
    const { canvas } = Drawer.getInstance();
    canvas.getCommandStack().undo();
  };
  const redo = () => {
    const { canvas } = Drawer.getInstance();
    canvas.getCommandStack().redo();
  };

  const deleteNode = () => {
    const { canvas } = Drawer.getInstance();
    const node = canvas.getPrimarySelection();
    const command = new draw2d.command.CommandDelete(node);
    canvas.getCommandStack().execute(command);
  };

  const onEditChange = (e) => {
    const { canvas } = Drawer.getInstance();
    if (e) {
      canvas.installEditPolicy(new draw2d.helper.CopyInterceptorPolicy());
    } else {
      canvas.installEditPolicy(new draw2d.policy.canvas.ReadOnlySelectionPolicy());
    }
    canvas.figures.data.forEach((figure) => {
      if (figure.onlyOnEditMode) {
        figure.setVisible(e, 250);
        if (figure.children.get(0)) {
          figure.children.get(0).figure.setVisible(e, 250);
        }
      }
    });
    setEditing(e);
  };

  const onSave = async () => {
    const { canvas } = Drawer.getInstance();
    const writer = new draw2d.io.json.Writer();
    writer.marshal(canvas, (json) => {
      const result = json
        .filter(({ id }) => !id.startsWith('no-save')) // remove all the no save objects
        .map((value) => {
          if (value.type === 'draw2d.helper.ChamberEntrance') {
            delete value.ports;
            return value;
          }
          return value;
        });
      console.log(result);
      // axios.post('http://localhost:4000/data', result);
      localStorage.setItem('_minemap', JSON.stringify(result));
      onEditChange(false);
    });
  };

  const reset = () => {
    localStorage.setItem('_minemap', '[]');
    window.location.reload();
  };

  return (
    <Row className="navbar">
      <Col span={4}>
        <Button icon={<UndoOutlined />} onClick={undo} />
        <Button icon={<RedoOutlined />} onClick={redo} />
      </Col>
      <Col span={2}>
        <Button icon={<DeleteOutlined />} onClick={deleteNode} />
      </Col>
      <Col span={7}>
        {/*<Row justify="end">*/}
        {/*  <Button onClick={() => zoom(false)} icon={<ZoomInOutlined />} />*/}
        {/*  <Button onClick={() => zoom(true)} icon={<ZoomOutOutlined />} />*/}
        {/*  <Button onClick={() => zoomReset()} icon={<FullscreenOutlined />} />*/}
        {/*</Row>*/}
      </Col>
      <Col span={3}>
        <Row justify="end" style={{ color: 'white', marginTop: 5 }}>
          Editing:
        </Row>
      </Col>
      <Col span={3}>
        <Switch
          style={{ marginTop: 5, marginLeft: 5 }}
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          checked={editing}
          onChange={onEditChange}
        />
      </Col>
      <Col span={5}>
        <Button type="primary" disabled={!editing} onClick={onSave}>
          Save
        </Button>
        <Button onClick={reset}>Reset</Button>
      </Col>
    </Row>
  );
};

export default Navbar;
