import React, { useState } from 'react';
import Canvas from './Draw/Canvas';
import Navbar from './Navbar';

const Layout = () => {
  const [editing, setEditing] = useState(false);

  return (
    <div>
      <Navbar editing={editing} setEditing={setEditing} />
      <Canvas editing={editing} />
    </div>
  );
};

export default Layout;
